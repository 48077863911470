import { fetchAuthSession } from "aws-amplify/auth";

const awsgateway = {
  API: {
    endpoints: [
      {
        name: "PortalApi",
        endpoint:
          "https://io-wealtlink-ds.gateway.hubwise.co.uk/api/hubwise-api",
        custom_header: async () => {
          return {
            Authorization: (await fetchAuthSession()).tokens?.accessToken
          };
        },
      },
      {
        name: "IntellifloApi",
        endpoint: "https://io-integration-ds.gateway.hubwise.co.uk",
      },
      {
        name: "UserManagementApi",
        endpoint:
          "https://583zde0vg0.execute-api.eu-west-2.amazonaws.com/ds/v1/user-management",
        custom_header: async () => {
          return {
            Authorization: (await fetchAuthSession()).tokens?.idToken
          };
        },
      },
      {
        name: "ChangelogAPi",
        endpoint1 : "https://api-changelog.hubwise.co.uk/changelog?stage=ds",
        endpoint2 : "https://api-changelog.hubwise.co.uk/changelog/id?environment=development&stage=ds",
        custom_header: async () => {
          return {
            Authorization: (await fetchAuthSession()).tokens?.idToken,
            Environment: "development",
          };
        },

      }
    ],
  },
};

export default awsgateway;
