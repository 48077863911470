import React, { forwardRef } from "react";

import {
  TableCell,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Adviser } from "../../model";
import adviserStore from "../../api";

interface TableRowProps {
    row: Adviser;
    openDialog: boolean;
    setOpenDialog: (a: boolean) => void;
    dialogMessage: string;
    setDialogMessage: (a: string) => void;
    dialogTitle: string;
    setDialogTitle: (a: string) => void;
}

const AdviserUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;
  const { openDialog, setOpenDialog, dialogMessage, setDialogMessage, dialogTitle, setDialogTitle } = props;
  const { disableMFAAdviser } = adviserStore;

  const handleDisableMFA = async () => {
    try {
      await disableMFAAdviser({ username: row.username, status: false });
      setDialogTitle("Success");
      setDialogMessage(`MFA disabled successfully for ${row.username}`);
      console.log("hi");
      setOpenDialog(true); // Open dialog for success message
    } catch (error) {
      setDialogTitle("Error");
      setDialogMessage(`Error disabling MFA for ${row.username}`);
      console.error("Error disabling MFA:", error);
      setOpenDialog(true); // Open dialog for error message
    }
  };

  const handleCloseDialog = () => {
    console.log("called");
    setOpenDialog(false); // Close the dialog
  };

  const options: MenuItem[] = [
    {
      label: "Edit",
      onClick: () => navigate("/adviser-users/add-edit/" + row.id)
    },
    {
      label: "Disable MFA",
      onClick: handleDisableMFA,
    },
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row?.first_name} {row?.last_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.phone_number}</TableCell>
        <TableCell>{row?.created_at}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "transparent" }, // Makes the backdrop transparent
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white for dialog content
            boxShadow: "10px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default AdviserUsersRow;
